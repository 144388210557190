<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item v-if="this.userInfo.user.roles[0].id == 1" :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>商户</el-breadcrumb-item>
                    <el-breadcrumb-item>应用管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <div class="listbox">
                <div class="listright" v-loading="loading">
                    <!-- <div class="searchleft">
                        <el-form :inline="true" :model="searchform" class="demo-form-inline" @submit.native.prevent >
                            <el-form-item label="应用名称">
                                <el-input v-model="searchform.blurry" @keyup.enter.native="onSubmit" size="small" :placeholder="$t('bookName')"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button size="small" type="primary" @click="onSubmit">{{$t('query')}}</el-button>
                            </el-form-item>
                        </el-form>
                    </div> -->
                    <div class="searchright">
                        <el-button size="small" type="primary" @click="openadd">+添加应用</el-button>
                    </div>
                    <div class="top"></div>
                    <el-table :data="list" :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}" style="width: 100%">
                        <el-table-column prop="applyId" label="应用id"></el-table-column>
                        <el-table-column prop="applyName" label="应用名称"></el-table-column>
                        <el-table-column prop="type" label="业务类型">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.type == 1" type="info">游戏</el-tag>
                                <el-tag v-if="scope.row.type == 2" type="info">直播</el-tag>
                                <el-tag v-if="scope.row.type == 3" type="info">金融</el-tag>
                                <el-tag v-if="scope.row.type == 4" type="info">电商</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="account" label="账户"></el-table-column>
                        <el-table-column prop="appluStatus" label="应用状态">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.appluStatus == 1" type="info">启动</el-tag>
                                <el-tag v-if="scope.row.appluStatus == 0" type="warning">禁止</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createTime" label="创建时间" :width="160">
                            <template slot-scope="scope">
                                {{scope.row.createTime|changedate}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="jobName" :label="$t('operator')" width="120">
                            <template slot-scope="scope">
                                <el-button size="small" @click="openedit(scope.row)" type="primary" icon="el-icon-edit"
                                    circle plain style="margin-right:5px;"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <pop-up :title="status == 1 ? '添加应用' : '编辑应用'" :isshow="showadd" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box" style="width: 700px;">
                <el-form ref="form" :inline="true" :model="form" :rules="rules" size="small">
                    <el-form-item label="应用名称" prop="applyName" placeholder="请输入应用名称">
                        <el-input v-model="form.applyName" />
                    </el-form-item> 
                    <el-form-item label="账户" prop="account">
                        <el-select v-model="form.account" placeholder="请选择">
                            <el-option v-for="item in channellist" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="业务类型" prop="type">
                        <el-select v-model="form.type" placeholder="请选择">
                            <el-option label="游戏" value="1"></el-option>
                            <el-option label="直播" value="2"></el-option>
                            <el-option label="金融" value="3"></el-option>
                            <el-option label="电商" value="4"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item :label="$t('status')">
                        <el-radio-group v-model="form.appluStatus">
                            <el-radio :label="1">启动</el-radio>
                            <el-radio :label="0">禁止</el-radio>
                        </el-radio-group>
                    </el-form-item> -->
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
    import apiurl from "@/api/public"
    import _api from "@/api/index"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    export default {
        components: {
            Treeselect
        },
        data() {
            var rolesValid = (rule, value, callback) => {
                callback()
            }
            return {
                form: {
                    id: null,
                    applyId: null,
                    applyName: null,
                    type: null,
                    account:null,
                    appluStatus:1,
                },
                rules:{
                    applyName:[{ required: true, trigger: 'blur' },],
                    account:[{ required: true, trigger: 'blur' },],
                    type:[{ required: true, trigger: 'blur' },],
                },
                defaultProps: {
                    label: "name",
                    isLeaf: 'isLeaf'
                },
                    searchform: {
                    blurry: "",
                },
                loading: false,
                showadd: false,
                list: [],
                value1:[],
                channellist:[],
            }
        },
        created() {
            this.userInfo=JSON.parse(localStorage.getItem("userinfo"))
            this.getList()
            this.getchannel()
        },
        methods: {
            getchannel() {
                _api.get(apiurl.channellist, {}).then(res => {
                    let newarr = []
                    for(let item of res.data){
                        newarr.push(item)
                    }
                    this.channellist = newarr
                })
            },
            openadd() {
                this.showadd = true
                this.status = 1
                this.form = {
                    id: null,
                    applyId: null,
                    applyName: null,
                    type: null,
                    account:null,
                    appluStatus:1,
                }
            },
            openedit(row) {
                this.status = 2
                this.form.id = row.id
                this.form.applyId = row.applyId
                this.form.applyName = row.applyName
                this.form.type = row.type
                this.form.account = row.account
                this.form.appluStatus = row.appluStatus
                this.showadd = true
            },
            getclose() {
                this.$refs.form.resetFields();
                this.showadd = false
            },
            handleNodeClick(mydata, node, obj) {
                this.loading = true
                this.getList()
            },
            deleteproduct(row) {
                _api.delete(apiurl.accountBook, row.id).then(res => {
                    this.$notify({
                        title: this.$t('success'),
                        message: this.$t('delSuccess'),
                        type: 'success'
                    });
                    this.getList()

                }).catch(err => {
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    });
                })
            },
            getconfirm() {
                if(this.form.applyName==null || this.form.applyName==""){
                    this.$message.error(this.$t('pleaseBookName'))
                    return
                }
                if(this.form.type < this.form.type){
                    this.$message.error(this.$t('startLgend'))
                    return
                }
                if (this.status == 1) { //如果是添加
                    _api.post(apiurl.accountBook, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('addSuccess')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            applyId: null,
                            applyName: null,
                            type: null,
                            account:null,
                            appluStatus:1,
                        }
                    }).catch(err => {
                        this.$notify.error({
                            title: this.$t('error'),
                            message: err.data.message
                        })
                    })
                } else { //如果是编辑
                    _api.put(apiurl.accountBook, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('editSuccess')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            applyId: null,
                            applyName: null,
                            type: null,
                            account:null,
                            appluStatus:1,
                        }
                    }).catch(err => {
                        this.$notify.error({
                            title: this.$t('error'),
                            message: err.data.message
                        })
                    })
                }
                  
            },
            getList() {
                this.loading = true
                _api.get(apiurl.accountBook, {
                    blurry: this.searchform.blurry,
                    nation:localStorage.getItem("nation"),
                }).then(res => {
                    this.loading = false
                    this.blurry=null
                    this.list = res.data
                })
            },
            onSubmit() {
                this.getList()
            },
            
        }
    }
</script>
<style scoped>
    .lefttop {
        padding: 8px;
    }

    .listbox {
        display: flex;
    }

    .listleft {
        width: 20%;
        border: 1px solid #EBEEF5;
    }

    .listright {
        width: 100%;
        margin-left: 1%;
    }

    .top {
        margin-top: 15px;
    }

    .listrighttop {
        padding: 0 10px;
        background: rgb(245, 245, 245);
        height: 40px;
        overflow: hidden;
        line-height: 40px;
        border-bottom: 1px solid #EBEEF5;
    }

    .listrighttop strong {
        float: left;
    }

    .listrighttop span {
        float: right;
    }

    ::v-deep .vue-treeselect__control,
    ::v-deep .vue-treeselect__placeholder,
    ::v-deep .vue-treeselect__single-value {
        height: 30px;
        line-height: 30px;
    }
</style>